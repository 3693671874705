
import React from "react"

import ApijwtusertypemenuForm from "../../components/webapps/forms/apijwtusertypemenu";

const UsertypemenuPage = ({location}) => {

	return <ApijwtusertypemenuForm
				location={location}
				allowadd={true}
				allowdelete={true}
			/>


}


export default UsertypemenuPage;
