
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../form"

const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="apijwtuserjsonmenu"

// TODO Additional security/checks for user and usertype updating

const usertypepickerfields = [
	{"label":"Name", "dbfield": "usertype_name", "type": "text", "filtertype": "textbox"},
	{"label":"Description", "dbfield": "usertype_description", "type": "text", "filtertype": "textbox"},
];

const usertypepickerfilter = [
	//{"field":"onsorganization_id", "operation": ">", "value": 0},
];


const apijwtmenusetpickerfields = [
	{"label":"Name", "dbfield": "apijwtuserjsonmenutype_name", "type": "text", "filtertype": "textbox"},
	{"label":"Description", "dbfield": "apijwtuserjsonmenutype_description", "type": "text", "filtertype": "textbox"},
];

const apijwtmenusetpickerfilter = [
	//{"field":"onsorganization_id", "operation": ">", "value": 0},
];


const searchFields = [
	{"label":"Menu Set", "dbfield": "apijwtuserjsonmenutype_name", "type": "text", "filtertype": "text"},
	{"label":"User Type", "dbfield": "usertype_name", "type": "text", "filtertype": "text"}
];


const newFormFields = [
	[
		{"label":"Menu Set", "field": "apijwtuserjsonmenutype_name", "value": "", "input": "picker", "mode": "required", "pickerfields": apijwtmenusetpickerfields, "pickerfilters": apijwtmenusetpickerfilter},
		{"label":"User Type", "field": "usertype_name", "value": "", "input": "picker", "mode": "required", "pickerfields": usertypepickerfields, "pickerfilters": usertypepickerfilter},
	]
];

const formFields = [
	[
		{"label":"Menu Set", "field": "apijwtuserjsonmenutype_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"User Type", "field": "usertype_name", "value": "", "input": "picker", "mode": "readonly"},
	]
];

//swebappsiamnet/12/apijwtuserjsonmenu_data/1

const commonFields = [
	{"label":"Menu Set Code", "field": "apijwtuserjsonmenutype_prefix", "value": "", "input": "hidden", "mode": "normal"},
	//{"label":"JSON Data", "field": "apijwtuserjsonmenu_data", "value": "", "input": "textfile", "mode": "required", "info":"Sample: {\"title\": \"Main Menu\",\"url\":\"user/userlogin\",\"subitems\": [{\"title\": \"Sub Menu (optional)\",\"url\": \"user/userlogin\"}]}", "uploadtype": "webapp"},
	{"label":"JSON Data", "field": "apijwtuserjsonmenu_data", "value": "", "input": "textfile", "mode": "required", "info":"Sample: {\"title\": \"Main Menu\",\"url\":\"user/userlogin\",\"subitems\": [{\"title\": \"Sub Menu (optional)\",\"url\": \"user/userlogin\"}]}", "filehost": "files.webapps.iamnet.com.ph", "filelocation":"menu/source/nn/userjsonmenutype/uploads", "uploadtype":"https"},
];


const ApijwtusertypemenuForm = ({location, allowadd, allowdelete}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		/*
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}
		*/

		//callback({"status":"Error", "message":"Debug"}); return;

		// TODO: Update final JSON file
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			callback(response);
		});

	}


	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "apijwtuserjsonmenu_data") {
				try {
					JSON.parse(mainform[idx].value);
					// todo additional checking
				} catch(checkjsonerr) {
					return {"status":"Error", "message":"Error"+checkjsonerr.message.replace("JSON.parse","")};

				}
			}
			idx++;
		}

		return {"status":"OK"};
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={allowadd}
				allowdelete={allowdelete}
				pagetitle={pagetitle}
				mobilerowtitlefield={["apijwtuserjsonmenutype_name", "usertype_name"]}
				searchFields={searchFields}
				editFields={[formFields[0].concat(commonFields)]}
				newformFields={[newFormFields[0].concat(commonFields)]}
				customSubmit={customSubmit}
				customValidateValues={formValidateValues}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default ApijwtusertypemenuForm;
